import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshopNoTab,
  InternalInstructor,
  InternalTestimonial
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './terapia-floral-de-A-a-Z.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Terapia Floral de A a Z com Florais de Bach' />
      <InternalHeader className={styles.header} withType>
        <small>Online</small>
        <h1>
          Terapia Floral de A a Z
          <br />
          <strong>com Florais de Bach</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              Um curso voltado para o desenvolvimento do processo terapêutico com Florais de Bach e a identificação das
              emoções em desequilíbrio para criar o livre fluxo das energias superiores através da personalidade,
              permitindo a Alma o retorno à sua jornada original. O conteúdo do curso está baseado no princípio do
              Floral de transformação das dores da Alma e na sua atuação especificamente no estado emocional. Ao longo
              das aulas desenvolvemos o conceito de que o efeito das essência florais não é a supressão das emoções em
              desequilíbrio ou atitudes negativas, mas sua transformação em atitudes positivas, estimulando o potencial
              de auto cura. Cada essência é associada a uma emoção humana básica, Mimulus, por exemplo, representa um
              tipo de emoção sentida quando estamos ansiosos ou com medo de algo em particular. Tomar esse floral nos
              ajudará a ultrapassar nosso medo e a encontrar a coragem necessária para enfrentar esse estado emocional.
            </p>
            <h2>Objetivo</h2>
            <p>
              O objetivo do curso é preparar o terapeuta floral para trabalhar com base na identificação das emoções em
              desequilíbrio seguindo o preceito da terapia floral que não separa o indivíduo do seu sintoma.
              Consideramos o corpo físico como reflexo das condições mentais, emocionais e espirituais, segundo Dr Bach,
              a causa e cura real das doenças não se encontram no corpo físico e sim na dissonância entre nossa Alma e
              nossa personalidade. Ao final do curso e das mentorias, o terapeuta floral terá a compreensão do indivíduo
              do seu contexto emocional e de desenvolvimento, sendo capaz de conceber uma fórmula específica e assertiva
              para o processo individual de cada um.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Vida e Obra do Dr Edward Bach
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Usos e aplicações dos Florais de Bach
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach para aqueles hipersensíveis a influência externa, que precisam ser mestres de si mesmos
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach para aqueles que sofrem pela solidão, que precisam abrir-se para os outros
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach para aqueles que precisam encarar seus medos e encontrar sua coragem
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach para aqueles que sofrem pelo cuidado excessivo com o outro – Viva livre e deixe viver
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach para aqueles que se sentem indecisos e precisam reconhecer seus pensamentos
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach para aqueles desinteressados nas circunstancias atuais, que precisam viver o presente
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Florais de Bach para aqueles que se sentem desanimados, encontre a saída para seu sofrimento
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Selecionando os Florais de Bach para uma fórmula personalizada e assertiva
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O processo terapêutico com Florais de Bach
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A prática Clínica com Florais de Bach
              </dt>
            </dl>
            <br />
            <br />
            <h3>Aulas gravadas com mentorias mensais ao vivo</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Sempre na 4º terça-feira do mês temos um encontro ao vivo para tirar dívidas e discussão dos casos
                clínicos
              </dt>
            </dl>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshopNoTab>
              <h3>
                <small>Curso Online</small>
                Terapia Floral de A a Z com Florais de Bach
              </h3>
              <ul>
                <li>Aulas online por plataforma digital de fácil interação</li>
                <li>Você assiste quando quiser</li>
                <li>Disponíveis imediatamente</li>
                <li>Tire suas dúvidas durante as mentorias mensais ao vivo</li>
                <li>Material em pdf incluso</li>
                <li>Certificado de conclusão </li>
                <li>Certificado de supervisão e prática clínica </li>
                <li>Sem Pré-requisito </li>
              </ul>
              <a
                href={WHATSAPPLINK}
                target='_blank'
                rel='noopener noreferrer'
                className={`button button--purpleReverse button internalWorkshop-button`}
              >
                Inscrição Curso Online <small></small>
              </a>
            </InternalWorkshopNoTab>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
